/*************************************************************************************
* formAppend
*************************************************************************************/
const init = function() {

	var minCount = 1;
	var maxCount = 6;
	
	$('.unit_plus').on('click', function(){
		var inputCount = $('#workHistory .unit').length;

		if (inputCount < maxCount){
			var element = $('#workHistory .unit:last-child').clone(true);// 末尾をイベントごと複製

			// 複製したinputのクリア
			var inputList = element[0].querySelectorAll('input[type="text"], textarea, select');
			for (var i = 0; i < inputList.length; i++) {
				inputList[i].value = "";
			}
			$('#workHistory .unit').parent().append(element);// 末尾追加

			// 職歴数のカウント
			var num = $('.unit:last-child').index();
			num = num + 1;
			$('.unit:last-child .workHistory_num').text('職歴' + num);
		}
	});
	// 削除
	$('.unit_minus').on('click', function(){// イベントごと複製しているのでonのselectorは未設定
		var inputCount = $('#workHistory .unit').length;
		if (inputCount > minCount){

			$$$.dialog.open({
				txt: '削除してもよろしいですか？',
				btns: [{
					name: '戻る',
				},
				{
					name: 'OK',
					afterClose: function(){
						$('#workHistory .unit:last-child').closest('.unit').remove();
					},
				}]
			})
		}
	});


};

module.exports = {
	init,
};