/*************************************************************************************
* imgChange
*************************************************************************************/
const init = function() {

    const triggers = document.querySelectorAll('.js-trigger'); 
    const images = document.querySelectorAll('.js-target');
    
    triggers.forEach(trigger => {
    trigger.addEventListener('mouseover', (e) => {
        const item = e.currentTarget;
        const target = item.getAttribute('data-target');
        const current = document.getElementById(target);
        images.forEach(image => {
        image.classList.remove('current');
        });
        current.classList.add('current');
    });
    });
}

module.exports = {
	init,
};