/*************************************************************************************
* chart
*************************************************************************************/
const init = function() {

  const bp = 737;

  const setObserver_3 = () => {
    
    const callback = (entries, observer) => {
      entries.forEach( (entry) => {
        if (entry.isIntersecting) {

          //----------------------------年齢構成比

          if ($(entry.target).hasClass('myChart')) {
            const ctx = entry.target.getContext('2d');
            ctx.canvas.width= 400;
            ctx.canvas.height= 300;
            const chart = new Chart(ctx, {
              type: 'bar',
              data: {
                labels: [ '20～24歳', '25～29歳', '30～34歳', '35～39歳', '40～44歳', '45歳以上', ],
                datasets: [{
                backgroundColor: ['#e2e9f1','#b7c6dc','#e2e9f1','#e2e9f1','#e2e9f1'],
                hoverBackgroundColor: ['#e2e9f1','#b7c6dc','#e2e9f1','#e2e9f1','#e2e9f1'],
                data: [6, 45, 24, 11, 10, 4],
                }]
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                indexAxis: 'y',
                plugins: {
                  legend: false,
                  tooltip: false,
                },
                layout: {
                  padding: { right: 30}
                },
                scales: {
                  x: {
                    display: false,
                    max: 45,
                  },
                  y: {
                    grid: {	display: false },
                    border: { display: false },
                    gridLines: { display: false },
                    ticks: { font:{ size: 14 } },
                  }
                },
              }
            });
          } 

          //----------------------------勤続年数

          if ($(entry.target).hasClass('myChart_2')) {
            const ctx_2 = entry.target.getContext('2d');
            ctx_2.canvas.width= 340;
            ctx_2.canvas.height= 340;
            const chart_2 = new Chart(ctx_2, {
              type: 'doughnut',
              data: {
                datasets: [{
                  data: [44, 24, 21, 11],
                  backgroundColor: ['#e2e9f1','#d3ddec','#bfcde1','#afc1d9'],
                  hoverBackgroundColor: ['#e2e9f1','#d3ddec','#bfcde1','#afc1d9'],
                  hoverBorderColor: ['#fff'],
                }]
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: false,
                  tooltip: false,
                },
              }
            });
          } 

          //----------------------------通勤時間
          
          if ($(entry.target).hasClass('myChart_3')) {
            const ctx_3 = entry.target.getContext('2d');
            console.log(ctx_3);
            ctx_3.canvas.width= 250;
            ctx_3.canvas.height= 250;
            const chart_3 = new Chart(ctx_3, {
              type: 'pie',
              data: {
                datasets: [{
                  data: [16, 49, 25, 10],
                  backgroundColor: ['#e2e9f1','#d3ddec','#bfcde1','#afc1d9'],
                  hoverBackgroundColor: ['#e2e9f1','#d3ddec','#bfcde1','#afc1d9'],
                  hoverBorderColor: ['#fff'],
                }]
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: false,
                  tooltip: false,
                },
              }
            });
          } 

          //----------------------------通勤で使う路線

          else if ($(entry.target).hasClass('myChart_4')) {
            const ctx_4 = entry.target.getContext('2d');
            ctx_4.canvas.width= 480;
            ctx_4.canvas.height= 280;
            const chart_4 = new Chart(ctx_4, {
              type: 'bar',
              data: {
                labels: [ '日比谷線', '都営新宿線', '都営浅草線', '京浜東北線', '東西線', 'TX', ],
                datasets: [{
                  backgroundColor: ['#b7c6dc','#e2e9f1','#e2e9f1','#e2e9f1','#e2e9f1','#e2e9f1'],
                  hoverBackgroundColor: ['#b7c6dc','#e2e9f1','#e2e9f1','#e2e9f1','#e2e9f1','#e2e9f1'],
                  data: [33, 23, 20, 7, 6, 4],
                }]
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                indexAxis: 'y',
                plugins: {
                  legend: false,
                  tooltip: false,
                },
                scales: {
                  x: {
                    display: false,
                    max: 36,
                  },
                  y: {
                    grid: {	display: false },
                    border: { display: false },
                    gridLines: { display: false },
                    ticks: { font:{ size: 14 } },
                  }
                },
              }
            });
          }

          //----------------------------文系・理系・体育会系？

          else if ($(entry.target).hasClass('myChart_5')) {
            const ctx_5 = entry.target.getContext('2d');
            ctx_5.canvas.width= 400;
            ctx_5.canvas.height= 300;
            if (window.innerWidth < bp) {
              ctx_5.canvas.height= 500;
            }
            const chart_5 = new Chart(ctx_5, {
              type: 'bar',
              data: {
                labels: [ '文系', '理系', '体育会系'],
                datasets: [{
                  backgroundColor: ['#b7c6dc','#e2e9f1','#e2e9f1'],
                  hoverBackgroundColor: ['#b7c6dc','#e2e9f1','#e2e9f1'],
                  data: [72, 24, 4],
                }]
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: false,
                  tooltip: false,
                },
                scales: {
                  x: {
                    grid: {	display: false },
                    border: { display: false },
                    gridLines: { display: false },
                    ticks: { font:{ size: 16 } },
                  },
                  y: {
                    display: false,
                  }
                },
              }
            });
          }

          //----------------------------眼鏡？コンタクト？

          else if ($(entry.target).hasClass('myChart_6')) {
            const ctx_6 = entry.target.getContext('2d');
            ctx_6.canvas.width= 300;
            ctx_6.canvas.height= 300;
            const chart_6 = new Chart(ctx_6, {
              type: 'doughnut',
              data: {
                datasets: [{
                  data: [28, 25, 44, 3],
                  backgroundColor: ['#e2e9f1','#d3ddec','#bfcde1','#afc1d9'],
                  hoverBackgroundColor: ['#e2e9f1','#d3ddec','#bfcde1','#afc1d9'],
                  hoverBorderColor: ['#fff'],
                }]
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: false,
                  tooltip: false,
                },
              }
            });
          }

          //----------------------------スマートフォンどっち派？

          else if ($(entry.target).hasClass('myChart_7')) {
            const ctx_7 = entry.target.getContext('2d');
            ctx_7.canvas.width= 300;
            ctx_7.canvas.height= 100;
            const chart_7 = new Chart(ctx_7, {
              type: 'bar',
              data: {
                labels: [ '', ''],
                datasets: [{
                  backgroundColor: ['#c7e4cf'],
                  hoverBackgroundColor: ['#c7e4cf'],
                  barThickness: 50,
                  data: [21],
                },{
                  backgroundColor: ['#e7eaef'],
                  hoverBackgroundColor: ['#e7eaef'],
                  barThickness: 50,
                  data: [79],
                }]
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                indexAxis: 'y',
                plugins: {
                  legend: false,
                  tooltip: false,
                },
                layout: {
                  padding: { right: 10}
                },
                scales: {
                  x: {
                    display: false,
                    stacked: true,
                    max: 100,
                  },
                  y: {
                    stacked: true,
                    grid: {	display: false },
                    border: { display: false },
                    gridLines: { display: false },
                  }
                },
              }
            });
          }

          //----------------------------あなたは犬派？猫派？

          else if ($(entry.target).hasClass('myChart_8')) {
            const ctx_8 = entry.target.getContext('2d');
            ctx_8.canvas.width= 500;
            ctx_8.canvas.height= 180;
            const chart_8 = new Chart(ctx_8, {
              type: 'bar',
              data: {
                labels: [ '', ''],
                datasets: [{
                  backgroundColor: ['#d3ddec'],
                  hoverBackgroundColor: ['#d3ddec'],
                  barThickness: 180,
                  data: [55],
                },{
                  backgroundColor: ['#f8e0dd'],
                  hoverBackgroundColor: ['#f8e0dd'],
                  barThickness: 180,
                  data: [37],
                },{
                  backgroundColor: ['#f7f7cd'],
                  hoverBackgroundColor: ['#f7f7cd'],
                  barThickness: 180,
                  data: [8],
                }]
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                indexAxis: 'y',
                plugins: {
                  legend: false,
                  tooltip: false,
                },
                layout: {
                  padding: { right: 10}
                },
                scales: {
                  x: {
                    display: false,
                    stacked: true,
                    max: 100,
                  },
                  y: {
                    stacked: true,
                    grid: {	display: false },
                    border: { display: false },
                    gridLines: { display: false },
                  }
                },
              }
            });
          }

          //----------------------------シフトの好きなところ

          else if ($(entry.target).hasClass('myChart_9')) {
            const ctx_9 = entry.target.getContext('2d');
            ctx_9.canvas.width= 280;
            ctx_9.canvas.height= 280;
            const chart_9 = new Chart(ctx_9, {
              type: 'doughnut',
              data: {
                datasets: [{
                  data: [38, 22, 20, 20],
                  backgroundColor: ['#e2e9f1','#d3ddec','#bfcde1','#afc1d9'],
                  hoverBackgroundColor: ['#e2e9f1','#d3ddec','#bfcde1','#afc1d9'],
                  hoverBorderColor: ['#fff'],
                }]
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: false,
                  tooltip: false,
                },
              }
            });
          }

          observer.unobserve(entry.target); // ← 画面内に入ったタイミングで監視を終了
        }
      }); 
    }
    
    const options = {
      threshold: [0.3,0.7]
    }
    
    const observer_4 = new IntersectionObserver(callback, options); 
    const targets_4 = document.querySelectorAll('[id*="myChart"]');
    targets_4.forEach( (elem_4) => {
      observer_4.observe(elem_4);
    }); 

  }

  setObserver_3();  
}

module.exports = {
	init,
};