/*************************************************************************************
* disabledToggle
*************************************************************************************/
const init = function(){
	$(this).each(function(index, el) {
		change.call($(this))
	});
}

const change = function(){
	const _type = $(this)[0].type || $(this)[0].localName
	const _target = $(this).data('disabled-target')
	const _flag = {
		option: $(this).prop('selected')
	}[_type] || $(this).prop('checked')
	$(`[data-disabled="${_target}"]`).prop('disabled', !!!_flag).trigger('disabledChanged')
}

module.exports = {
	init,
	change,
}
