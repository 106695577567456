// const { version } = require('core-js');

//jqueryをCDNで読み込むための対策
window.require = name => {
	if (name === 'jquery') return window.jQuery
	else throw new Error(`Cannot require ${name}`)
}
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* $$$ 外出し
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
const $$$ = window.$$$ = {};

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* プラグインrequire
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
window.AjaxZip3 = require('./_plugins/_ajaxzip3.custom');


/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* require utils
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$$$.anim = require('./_utils/_anim');
$$$.extraChange = require('./_utils/_extraChange.js');
$$$.extraChange.init();
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* require modules
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$$$.ua = require('./_modules/_ua');
$$$.pcsp = require('./_modules/_pcsp');
$$$.viewport = require('./_modules/_viewport');
$$$.windowInfo = require('./_modules/_windowInfo');
$$$.resizeend = require('./_modules/_resizeend');
$$$.smoothScroll = require('./_modules/_smoothScroll');
$$$.anchorJump = require('./_modules/_anchorJump');
$$$.overlay = require('./_modules/_overlay');
$$$.dialog = require('./_modules/_dialog');
// $$$.contentsModal = require('./_modules/_contentsModal');
$$$.disabledToggle = require('./_modules/_disabledToggle');
// $$$.displayToggle = require('./_modules/_displayToggle');
// $$$.fileForm = require('./_modules/_fileForm');
$$$.acc = require('./_modules/_acc');
// $$$.tab = require('./_modules/_tab');
// $$$.tree = require('./_modules/_tree');
// $$$.ajaxMore = require('./_modules/_ajaxMore');
// $$$.objFitPolyfill = require('./_modules/_objFitPolyfill');
// $$$.share = require('./_modules/_share');

// const Validator = require('./_modules/_validator/_validator');
// const ruleList = require('./_modules/_validator/_ruleList')
// const errMsgList = require('./_modules/_validator/_errorMsg/_ja')


$$$.mouseStalker = require('./_mymodules/_mouseStalker');
$$$.swiperBtnStalker = require('./_mymodules/_swiperBtnStalker');
$$$.imgChange = require('./_mymodules/_imgChange');
$$$.hamburger = require('./_mymodules/_hamburger');
$$$.acMenu = require('./_mymodules/_acMenu');
$$$.headerScroll = require('./_mymodules/_headerScroll');
$$$.filter = require('./_mymodules/_filter');
$$$.formAppend = require('./_mymodules/_formAppend');
$$$.appear = require('./_mymodules/_appear');
$$$.scrollspy = require('./_mymodules/_scrollspy');
$$$.countUp = require('./_mymodules/_countUp');
$$$.chart = require('./_mymodules/_chart');
// $$$.memberRandom = require('./_mymodules/_memberRandom');


/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* もろもろ実行
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$$$.ua.judge();
$$$.pcsp.init(736);
$$$.viewport.init(1240, 736);
$$$.windowInfo.init();
$$$.resizeend.init();
// $$$.fileForm.init();
// $$$.validator = new Validator($('[data-validate]')[0], ruleList, errMsgList);

$$$.mouseStalker.init();
$$$.swiperBtnStalker.init();
$$$.imgChange.init();
$$$.hamburger.init();
$$$.acMenu.init();
$$$.headerScroll.init();
$$$.filter.init();
$$$.formAppend.init();
$$$.appear.init();
$$$.scrollspy.init();
$$$.countUp.init();
$$$.chart.init();
// $$$.memberRandom.init();


/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* window load
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$(window).on('load', function(event) {
	$$$.anchorJump.init({
		adjust: 20,
		spAdjust: 20
	});
	// $$$.objFitPolyfill.init();
	$$$.acc.init.call($('[data-acc]'));
	// $$$.tab.init.call($('[data-tab]'));
	// $$$.tree.init.call($('[data-tree]'));
	// $$$.ajaxMore.init.call($('[data-ajax-more]'))
	$$$.disabledToggle.init.call($('[data-disabled-target]'));
	// $$$.displayToggle.init.call($('[data-display-target]'));
});


/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* window resize
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$(window).on('resize', function(event) {
	$$$.pcsp.judge();
	$$$.windowInfo.updateSize();
	$$$.windowInfo.omitScrollBarSize();
});

$(window).on('resizeend.any', function(event) {
	$$$.viewport.update();
	// $$$.objFitPolyfill.update();
});

$(window).on('resizeend.width', function(event) {
});

$(window).on('resizeend.height', function(event) {
});


/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* window pcsp.changed
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$(window).on('pcsp.changed', function(event, mode) {
	$$$.viewport.update();
	// $$$.objFitPolyfill.update();
});



/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* window scroll
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$(window).on('scroll', function(event) {
	$$$.windowInfo.updateScroll();
});



/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* click event
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/


//smoothScroll
$(document).on('click', '[href*="#"]:not([data-contents-modal])', function(event) {
	$$$.smoothScroll.scroll.call($(this), event, {
		adjust: 20,
		spAdjust: 20
	})
});

//overlay
$(document).on('click.overlay', '.overlay', function(event) {
	event.preventDefault();
	$$$.overlay.close();
});

//contents-modal
// $(document).on('click', '[data-contents-modal]', function(event) {
// 	event.preventDefault();
// 	$$$.contentsModal.open.call($(this))
// });


//fileForm ローカルファイルを選択
// $(document).on('click', '.fileForm_btn > a', function(event) {
// 	event.preventDefault();
// 	$$$.fileForm.add.call($(this));
// });

//fileForm 選択済みアイテム削除
// $(document).on('click', '.fileForm_del', function(event) {
// 	event.preventDefault();
// 	$$$.fileForm.remove.call($(this));
// });


/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* other event
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
//EnterでformのSubmit防止
$(document).on('keydown', '[data-no-submit-on-enter] input', function(event) {
	if ((event.which && event.which === 13) || (event.keyCode && event.keyCode === 13)) {
		event.preventDefault();
		return false;
	}
});

// disabledToggle
$(document).on('extraChange', '[data-disabled-target]', function(event) {
	event.preventDefault();
	$$$.disabledToggle.change.call($(this))
});

//displayToggle
// $(document).on('extraChange', '[data-display-target]', function(event) {
// 	event.preventDefault();
// 	$$$.displayToggle.change.call($(this))
// });




/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
* plugins
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
$(function () {

	let memberswiper;
	let isInited = false; // スライダーが初期化済みかどうかのフラグ
	const slideLength = document.querySelectorAll('.memberSwiper .swiper-slide').length; // 総スライド数を取得
	const bp = 736;

	const checkBreakpoint = () => {
		if (slideLength > 3 || (window.innerWidth < bp && slideLength > 1)) {
			if (!isInited) {
				// 総スライド数が slidesPerView より多く、スライダーがまだ初期化されていなかったら、スライダーを初期化
				initSwiper();
				isInited = true;
			}
		} else {
			$('.memberSwiper_next, .memberSwiper_prev').hide();
			if (isInited) {
				// 総スライド数が slidesPerView より少なく、スライダーが初期化済みだったら、スライダーを解除
				memberswiper.destroy(false, true);
				isInited = false;
			}
		}
	};

	const initSwiper = () => {
		memberswiper = new Swiper('.memberSwiper', {
		slidesPerView: 'auto',
		spaceBetween: 10,
		centeredSlides: true,
		loop: true,
		loopAdditionalSlides: 1,
		speed: 1000,
		autoplay: {
			delay: 4000,
			disableOnInteraction: false,
		},
		grabCursor: true,
		navigation: {
			nextEl: '.memberSwiper_next',
			prevEl: '.memberSwiper_prev',
		},
		breakpoints: {
			1025: {
			spaceBetween: 40,
			},
		},
		});
	};

	window.addEventListener('resize', function() {
		checkBreakpoint(); // リサイズ時に実行
	});
	checkBreakpoint(); // ロード時に初回実行



	const aboutswiper = new Swiper(".aboutSwiper", {
		loop: true,
		effect: "fade",
		autoplay: {
		  delay: 4000,
		  disableOnInteraction: false,
		},
		speed: 2000,
		allowTouchMove: false,
	});

	
	
	const galleryswiper = new Swiper(".gallerySwiper", {
		slidesPerView: "auto",
		spaceBetween: 10,
		centeredSlides: true,
		loop: true,
		loopAdditionalSlides: 1,
		speed: 1800,
		grabCursor: true,
		navigation: {
			nextEl: '.gallery_nextArea-1',
			prevEl: '.gallery_prevArea-1',
		},
		autoplay: {
			delay: 4000,
			disableOnInteraction: false,
		},
		breakpoints: {
			1025: {
			spaceBetween: 40,
			},
		},
	});
	const galleryswiper_2 = new Swiper(".gallerySwiper_2", {
		slidesPerView: "auto",
		spaceBetween: 10,
		centeredSlides: true,
		loop: true,
		loopAdditionalSlides: 1,
		speed: 1800,
		grabCursor: true,
		navigation: {
			nextEl: '.gallery_nextArea-2',
			prevEl: '.gallery_prevArea-2',
		},
		autoplay: {
			delay: 4000,
			disableOnInteraction: false,
		},
		breakpoints: {
			1025: {
			spaceBetween: 40,
			},
		},
	});
	const galleryswiper_3 = new Swiper(".gallerySwiper_3", {
		slidesPerView: "auto",
		spaceBetween: 10,
		centeredSlides: true,
		loop: true,
		loopAdditionalSlides: 1,
		speed: 1800,
		grabCursor: true,
		navigation: {
			nextEl: '.gallery_nextArea-3',
			prevEl: '.gallery_prevArea-3',
		},
		autoplay: {
			delay: 4000,
			disableOnInteraction: false,
		},
		breakpoints: {
			1025: {
			spaceBetween: 40,
			},
		},
	});
	const galleryswiper_4 = new Swiper(".gallerySwiper_4", {
		slidesPerView: "auto",
		spaceBetween: 10,
		centeredSlides: true,
		loop: true,
		loopAdditionalSlides: 1,
		speed: 1800,
		grabCursor: true,
		navigation: {
			nextEl: '.gallery_nextArea-4',
			prevEl: '.gallery_prevArea-4',
		},
		autoplay: {
			delay: 4000,
			disableOnInteraction: false,
		},
		breakpoints: {
			1025: {
			spaceBetween: 40,
			},
		},
	});


});





