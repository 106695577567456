/*************************************************************************************
 * mouseStalker
 *************************************************************************************/
const init = function () {
	$(function () {
		const bp = 736;
  
	  // PC幅の時のみ実行
		if (window.innerWidth > bp) {
		// マウスカーソルに付与するアニメーション用の要素
		var $cursor = $(".cursor");
  
		// マウスカーソルが対象要素内に入ったとき
		$(".member_link,.aboutNav_link").on("mouseover mousemove", function (e) {
		  // マウスカーソルの位置を監視
			var cursorPosX = e.pageX;
			var cursorPosY = e.pageY;
		  // アニメーションに使用する要素の位置調整用
			var cursorWidth = $cursor.width();
			var cursorHeight = $cursor.height();
  
		  // マウスカーソルに付与するアニメーション
			$cursor.css({
			left: cursorPosX - cursorWidth / 2,
			top: cursorPosY - cursorHeight / 1.6,
			});
  
			$cursor.addClass("active");
		});
  
		// マウスカーソルが対象要素内から外れたとき
		$(".member_link,.aboutNav_link").on("mouseout", function () {
			$cursor.removeClass("active");
		});
		} else {
		// SP幅の時は対象要素のイベントを削除
		$(".member_link,.aboutNav_link").off();
		}
	});
  };
  
  module.exports = {
	init,
  };
  