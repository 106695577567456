/*************************************************************************************
* scrollspy
*************************************************************************************/
const init = function() {

  const linkTargets = document.querySelectorAll('.messMain_sec');
 
  //オプション
  const options = {
    //上から25%の位置を交差の境界とする
    rootMargin: '-20% 0px -80%', 
  };
    
  const callback = (entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        highlightNavMenu(entry.target);
      }
    });
  }
   
  const observer = new IntersectionObserver(callback, options);
    
  //それぞれの監視対象の要素（linkTarget）を observe() に指定して監視
  linkTargets.forEach( target => {
    observer.observe(target);
  });
   
   
  //メニュー項目をハイライトする（色を変える）関数 
  const highlightNavMenu = (target) => {
    const highlightedMenu = document.querySelector('.messMain_list .active');
    
    if (highlightedMenu !== null) {
      highlightedMenu.classList.remove('active');
    }
    
    const href = '#' + target.id;
    const currentActiveMenu =  document.querySelector(`a[href='${href}']`);
    currentActiveMenu.classList.add('active');
  }

  // プログラムが効いていないので一時的しのぎ（コンパネ上でdiv.wrapperに付与するクラス、の項目が効いてない）
  const oya = $(".messMain").parents('.wrapper');
  oya.addClass("messWrapper");
}

module.exports = {
	init,
};