/*************************************************************************************
* filter
*************************************************************************************/
const init = function() {

	let $filteringBtn = $(".filter_btn li"),
	$image = $(".mMember_list li");

	// フィルタリングボタンがクリックされた時の処理
	$filteringBtn.click(function(){

		let target = $(this).attr("value");
		$filteringBtn.removeClass("active");
		$(this).addClass("active");

		// 各画像に対する処理
		$image.each(function(){
				// 初期値として全てを非表示にする
				$(this).animate({
						"opacity": 0
				}, 500, function(){
						$(this).hide();
						/* 
						指定の画像要素にクリックされたボタンのvalueのクラスが入っていれば表示
						value属性がallの場合も表示する
						*/
						if($(this).hasClass(target) || target == "all") {
								$(this).show();
								$(this).animate({
										"opacity": 1
								}, 500);
						}
				});
		});
	});
};

module.exports = {
	init,
};