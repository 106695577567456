/*************************************************************************************
* appear
*************************************************************************************/
const init = function() {

  const setObserver = () => {
    
    const callback = (entries, observer) => {
      entries.forEach( (entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
          observer.unobserve(entry.target); // ← 画面内に入ったタイミングで監視を終了
        }
      }); 
    }
    
    const options = {
      threshold: [0,1]
    }
  
    //messageページ専用
    const options_2 = {
      threshold: 0.5
    }
  
    
    const observer = new IntersectionObserver(callback, options); 
    const targets = document.querySelectorAll('[class*="target"]');
    targets.forEach( (elem) => {
      observer.observe(elem);
    });  

    //messageページ専用
    const observer_2 = new IntersectionObserver(callback, options_2); 
    const targets_2 = document.querySelectorAll('[class*="messAppear"]');
    targets_2.forEach( (elem_2) => {
      observer_2.observe(elem_2);
    });  
  }

  setObserver();  

}

module.exports = {
	init,
};