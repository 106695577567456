/*************************************************************************************
* acMenu
*************************************************************************************/
const init = function() {

$(window).on('load resize', function(){
	const winW = $(window).width();
	const devW = 767;
	if (winW <= devW) {
		//スマホ時
		$('.gNav_list > .acNav_outer').off('mouseenter mouseleave');
		
		$('.gNav_list > .acNav_outer').off('click').on('click', function(e) {
			$(this).find('.acNav').slideToggle();
			$(this).find('button').toggleClass('open');
		});
		$('.gNav_list > .acNav_outer .acNav').on('click', function(e) {
			e.stopPropagation();
		});
	}

	else {
		$('.gNav_list > .acNav_outer').off('click');
		$('.acNav').slideUp();

		//リサイズ字の「toggleClass('open')」のプラスマイナス表示調整。
		$('.gNav_list > .acNav_outer button').removeClass('open');

		$('.gNav_list > .acNav_outer').hover(function(){
			$(this).find('.acNav').stop().fadeIn();
			$('.acNav_bg ,.gNav_item-link').addClass('active');
			}, function(){
				$(this).find('.acNav').stop().fadeOut();
				$('.acNav_bg ,.gNav_item-link').removeClass('active');
			}
		);
	}
});


};

module.exports = {
	init,
};