/*************************************************************************************
* headerScroll
*************************************************************************************/
const init = function() {
	var _window = $(window),
	_header = $('.header'),
	_header_bg = $('.header_bg'),
	_header_contents = $('.header_contents'),
	mvBottom,
	startPos,
	winScrollTop;

_window.on('scroll',function(){
	winScrollTop = $(this).scrollTop();
	mvBottom = $('.mv').height();
	pageBottom = $('.pageHeader').height();
	pageTop = $('.pageTop').height();
	headerBottom = $('.header').height();
	if (winScrollTop >= mvBottom || winScrollTop >= pageBottom || winScrollTop >= pageTop) {
		_header_bg.addClass('active');
		_header_contents.addClass('active');
	} else {
		_header_bg.removeClass('active');
		_header_contents.removeClass('active');
	}
	if (winScrollTop >= startPos) {
		if (winScrollTop >= headerBottom) {
			_header.addClass('hide'); //消える
		}
	} else {
		_header.removeClass('hide'); //出る
	}
	startPos = winScrollTop;
});

_window.trigger('scroll');
}

module.exports = {
	init,
};

