/*************************************************************************************
 * swiperBtnStalker
 *************************************************************************************/
const init = function () {
	$(function () {
		const bp = 736;
  
		if (window.innerWidth > bp) {

		var $swiperCursor_prev = $(".swiperCursor_prev");
		var $swiperCursor_next = $(".swiperCursor_next");
  
		$(".gallery_nextArea").on("mouseover mousemove", function (e) {
			var cursorPosX = e.pageX;
			var cursorPosY = e.pageY;

			var cursorNextWidth = $swiperCursor_next.width();
			var cursorNextHeight = $swiperCursor_next.height();
  
			$swiperCursor_next.css({
			left: cursorPosX - cursorNextWidth / 2,
			top: cursorPosY - cursorNextHeight / 1.6,
			});
  
			$swiperCursor_next.addClass("active");
		});

		$(".gallery_prevArea").on("mouseover mousemove", function (e) {
			var cursorPosX = e.pageX;
			var cursorPosY = e.pageY;

			var cursorPrevWidth = $swiperCursor_prev.width();
			var cursorPrevHeight = $swiperCursor_prev.height();
  
			$swiperCursor_prev.css({
			left: cursorPosX - cursorPrevWidth / 2,
			top: cursorPosY - cursorPrevHeight / 1.6,
			});
  
			$swiperCursor_prev.addClass("active");
		});
  
		$(".gallery_nextArea,.gallery_prevArea").on("mouseout", function () {
			$swiperCursor_prev.removeClass("active");
			$swiperCursor_next.removeClass("active");
		});
		} else {
		$(".gallery_nextArea,.gallery_prevArea").off();
		}
	});
  };
  
  module.exports = {
	init,
  };
  