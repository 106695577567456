/*************************************************************************************
* countUp
*************************************************************************************/
const init = function() {


  const setObserver_2 = () => {
    
    const callback = (entries, observer) => {
      entries.forEach( (entry) => {
        if (entry.isIntersecting) {

          //数字のカウントアップ
          var countElm = $(entry.target);
          countSpeed = 20;
        
          countElm.each(function(){
            var self = $(this),
            countMax = self.attr('data-num'),
            thisCount = self.text(),
            countTimer;
        
            function timer(){
              countTimer = setInterval(function(){
                var countNext = thisCount++;
                self.text(countNext);
        
                if(countNext == countMax){
                  clearInterval(countTimer);
                }
              },countSpeed);
            }
            timer();
          });

          observer.unobserve(entry.target); // ← 画面内に入ったタイミングで監視を終了
        }
      }); 
    }
    
    const options = {
      threshold: [0.2,0.8]
    }  

    const observer_3 = new IntersectionObserver(callback, options); 
    const targets_3 = document.querySelectorAll('[class*="countUp"]');
    targets_3.forEach( (elem_3) => {
      observer_3.observe(elem_3);
    });  
  }

  setObserver_2();  
}

module.exports = {
	init,
};