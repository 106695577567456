/*************************************************************************************
* extraChange
*************************************************************************************/
const init = function(){
	$('input, select, textarea, option').each(function(index, el) {
		const _$this = $(this);
		const _type = this.type || this.localName
		let _$target = {
			radio: $(`[name="${_$this.attr('name')}"]`),
			option: _$this.closest('select'),
		}[_type] || _$this

		_$target.on('change.extraChange', function(event) {
			_$this.trigger('extraChange')
		});
	});
}


module.exports = {
	init,
}
